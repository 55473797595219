import React from "react";

const home = () => {
    return (
        <main style={{justifyContent: "center"}}>
            <img className="logo-accueil" src="./img/node-react.png"/>
        </main>
    )
};

export default home;

